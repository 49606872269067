
import {
  defineComponent,
  reactive,
  toRefs,
  ref,
  watch,
  onMounted,
  onUnmounted,
} from "vue";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import { format } from "date-fns";
import { th } from "date-fns/locale";
import { useRequest } from "vue-request";
import SatIcon from "@/components/SatIcon.vue";
import SatCardCarousel from "@/components/carousel/SatCardCarousel.vue";
import {
  CalendarOutlined,
  FileProtectOutlined,
  ContactsOutlined,
  EnvironmentOutlined,
  CodeOutlined,
  LoadingOutlined,
} from "@ant-design/icons-vue";
import SatBreadcrumb from "@/components/SatBreadcrumb.vue";
import { athleteService } from "@/_services";
import {
  Athlete,
  BaseResponse,
  BreadcrumbRoute,
  IAthlete,
  ICompetition,
  CompetitionInformation,
  SubCompetitionQueryParamsDto,
} from "@/_modules/types";
import useCompetitionRepositories from "@/_composables/useCompetitionRepositories";
import { Helpers } from "@/_modules/helpers";
import { useStore } from "vuex";

export default defineComponent({
  name: "CompetitionOverview",
  components: {
    SatIcon,
    SatCardCarousel,
    SatBreadcrumb,
    CalendarOutlined,
    FileProtectOutlined,
    ContactsOutlined,
    EnvironmentOutlined,
    CodeOutlined,
    LoadingOutlined,
  },
  setup() {
    const store = useStore();

    const competitionId = ref<number>(0);
    const currentPage = ref(1);
    const totalPages = ref(0);
    const limit = 2;
    const subWrapper = ref<any>();
    const { getCompetition, getSubCompetitions, fetchingSubCompetitions } =
      useCompetitionRepositories();
    const { t } = useI18n({
      useScope: "global",
    });
    const route = useRoute();
    const state = reactive({
      searching: "",
      loading: true,
      competition: {} as ICompetition,
      info: [
        {
          key: "code",
          title: {
            th:"รหัสการแข่งขัน",
            en:'Competition ID'
          },
          value: {
            th:'-',
            en:'-'
          },
          icon: "CodeOutlined",
          props: { class: "me-2 fs-5" },
        },
        {
          key: "level",
          title: {
            th:"ระดับการแข่งขัน",
            en:'Competition Level'
          },
          icon: "SatIcon",
          props: { name: "global", cssClass: "me-2 fs-5" },
          value: {
            th:'-',
            en:'-'
          },
        },
        // { key: 'type', title: 'หน่วยงาน/สมาคมผู้ดูแล', icon: 'SatIcon', props: { name: 'reward', cssClass: 'me-2 fs-5' }, value: '-' },
        {
          key: "teamType",
          title: {
            th:"ประเภททีม",
            en:'Team Type'
          },
          icon: "SatIcon",
          props: { name: "team", cssClass: "me-2 fs-5" },
          value:{
            th:'-',
            en:'-'
          },
        },
        {
          key: "totalSubCompetition",
          title: {
            th:"การแข่งขันย่อย",
            en:'Sub Competition'
          },
          icon: "SatIcon",
          props: { name: "reward", cssClass: "me-2 fs-5" },
          value: {
            th:'-',
            en:'-'
          },
        },
      ] as any,
      subCompetitions: [] as ICompetition[],
    });
    const routes = ref<BreadcrumbRoute[]>([
      {
        path: "competitions",
        breadcrumbName: t("label.overview.competitions"),
      },
      {
        breadcrumbName: "-",
      },
    ]);

    const onSearch = async (value: string) => {
      state.searching = value;
      const response = await fetchSubCompetition(competitionId.value, {
        page: 1,
        size: limit,
        ...(value && { name: value }),
      });
      currentPage.value = 1;
      state.subCompetitions = [...response];
    };

    const fetchSubCompetition = async (
      id: number,
      query: SubCompetitionQueryParamsDto
    ) => {
      const result = await getSubCompetitions(id, query);
      if (!result || !result.sub_competition) return [];
      const { sub_competition } = result;
      totalPages.value = +sub_competition.lastPage;
      return sub_competition.data;
      // console.log('result sidddd', result);
    };

    const loadMore = async () => {
      currentPage.value = currentPage.value + 1;
      var params: any = {
        page: currentPage.value,
        size: limit,
      };
      try {
        if (currentPage.value <= totalPages.value) {
          if (state.searching) {
            params = {
              ...params,
              name: state.searching,
            };
          }
          const response = await fetchSubCompetition(
            competitionId.value,
            params
          );
          state.subCompetitions = [...state.subCompetitions, ...response];
        }
      } catch (error) {}
    };

    const subLink = (subId: number) => route.path + "/" + subId;

    watch(
      () => route.params.id,
      async (newId) => {
        if (Number.isNaN(+newId)) return;
        competitionId.value = +newId;
        const result = await getCompetition(+newId);
        if (result) {
          state.competition = result;
          routes.value[1].breadcrumbName =
            store.state.currentLanguage === "th"
              ? state.competition.name?.th
              : state.competition.name?.en;
          const { info } = result;
          state.info.forEach((ele:any) => {
            if (Object.keys(info).includes(ele.key)) {
              switch (ele.key) {
                case "code":
                  ele.value = {
                    th:info.code,
                    en:info.code
                  }
                  break;
                case "level":
                  ele.value = {
                    th:info.level,
                    en:info.level
                  }
                  break;
                case "teamType":
                  ele.value = {
                    th:info.teamType.th ,
                    en:info.teamType.en
                  }
                  break;
                case "totalSubCompetition":
                  ele.value = {
                    th:info.totalSubCompetition,
                    en:info.totalSubCompetition
                  }
                  break;
              }
              // ele.value = Helpers.getKeyValue(
              //   info,
              //   ele.key as unknown as any
              // ).toString();
            }
          });
        }
        const response = await fetchSubCompetition(+newId, {
          page: currentPage.value,
          size: limit,
        });
        if (response?.length) {
          state.subCompetitions = [...response];
          currentPage.value++;
        }
      },
      { immediate: true }
    );
    return {
      t,
      store,
      ...toRefs(state),
      routes,
      onSearch,
      activeKey: ref(0),
      fetchingSubCompetitions,
      subLink,
      loadMore,
      currentPage,
      totalPages,
    };
  },
});
